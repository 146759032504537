import TabHandler from "../app/components/tab-handler";
import PaymentRouteTemplateForm from "../features/payment-route-template/components/pay-route-template-form";
import UserPercentageView from "./user-percentage-view";

export default function PaymentRouteTemplateView() {
  return (
    <>
      <TabHandler 
        padding={1}
        tabNames={["Templates", "Percentages"]}
        tabComponents={[
          <PaymentRouteTemplateForm />,
          <UserPercentageView />
        ]}
      /> 
    </>
  )
}
import { Done, ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import AppDateRangeSelector from "../../../app/components/app-date-range-selector";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeFilterPaymentsResumeOfficeAct, getOfficePaymentsResumeThunk } from "../office-dashboard.slice";
import TabHandler from "../../../app/components/tab-handler";
import { useParams } from "react-router-dom";
import { dateToInputDate } from "../../../utils/date.utils";
import OfficeDashboardPaymentsDoneTable from "./office-dashboard-payments-done-table";
import OfficeDashboardPaymentsExpectedTable from "./office-dashboard-payments-expected-table";

export default function OfficeDashboarPaymentsResume() {
  const {paymentResumeFilter} = useAppSelector(state => state.officeDashboard)
  const dispatch = useAppDispatch()
  const {dateEnd, dateStart} = paymentResumeFilter
  const {officeId} = useParams()

  const getPaymentResume = () => {
    dispatch(getOfficePaymentsResumeThunk({officeId: officeId!, dateStart: dateToInputDate(dateStart.toISOString()), dateEnd: dateToInputDate(dateEnd.toISOString())}))
  }
  return(
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreOutlined/>}>
          <Typography variant="h6">Payments</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={1}>
            <Grid item xs={10}>
              <AppDateRangeSelector id="officeDashboardCustomerResume" dateEnd={dateEnd} dateStart={dateStart}
                onChange={({dateStart, dateEnd}) => dispatch(changeFilterPaymentsResumeOfficeAct({dateStart, dateEnd}))}
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="outlined" size="small" onClick={getPaymentResume}> <Done/> </Button>
            </Grid>
          </Grid>
          <TabHandler
            tabNames={["Expected", "Done"]}
            tabComponents={[
              <OfficeDashboardPaymentsExpectedTable/>,
              <OfficeDashboardPaymentsDoneTable/>
            ]}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CustomersDatabaseState, UserAssignedWithCustomers, UserWithCustomersDatabaseType } from "./components/customer-disabled.state"
import { assignCustomerDatabaseToUsersReq, getCustomersDatabaseReq } from "../../app/services/customer.service"
import { getCurrentCampaignReq } from "../../app/services/campaign.service"
import { UserInterface } from "../../app/models/user.interface"
import { OfficeInterface } from "../../app/models/office.inteface"
import { store } from "../../app/store"

const initialState: CustomersDatabaseState = {
  loading: false,
  customers: [],
  rowCustomers: [],
  officesCampaignsWithUsersWithCustomers: [],
  userWithCustomers: []
}
export const getDisabledCustomersThunk = createAsyncThunk( "CustomersDatabase/getDisabledCustomers", async () => await getCustomersDatabaseReq())

export const getOfficesCampaignsDisabledThunk = createAsyncThunk( "CustomersDatabase/getOfficesCampaignsDisabledThunk", async ( ) =>
  await getCurrentCampaignReq()
)

export const assignCustomerDatabaseToUsersThunk = createAsyncThunk( "CustomersDatabase/assignCustomerDatabaseToUsersThunk", async (data: UserWithCustomersDatabaseType[]) =>
  {
    await assignCustomerDatabaseToUsersReq(data)
    store.dispatch(getDisabledCustomersThunk());
    return true
  }
)

export const CustomersDatabaseSlice = createSlice({
  name: "CustomersDatabase",
  initialState,
  reducers: {
    checkCustomerDisabledAct: (state, action: PayloadAction<{index: number, checked: boolean}>) => {
      state.rowCustomers[action.payload.index].checked = action.payload.checked
      distributeCustomers(state)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDisabledCustomersThunk.fulfilled, (state, action) => {
      state.rowCustomers = action.payload
      distributeCustomers(state)
    }).addCase(getOfficesCampaignsDisabledThunk.fulfilled, (state, action) => {
      const userWithCustomers: UserAssignedWithCustomers[] = []
      const officesCampaignsWithUsersWithCustomers = action.payload.officesCampaigns.map((officeCampaign) => {
        const {office, user, usersDatabase, _id} = officeCampaign
        const users = usersDatabase.map((u, i) => {
          const {_id, lastName, name} = u as UserInterface
          return ({_id, lastName, name, customers: []}) as unknown as UserAssignedWithCustomers
        })
        userWithCustomers.push(...users)
        return ({office: office as unknown as OfficeInterface, lead: user as unknown as UserInterface, users, _id})
      })
      state.officesCampaignsWithUsersWithCustomers = officesCampaignsWithUsersWithCustomers.sort((a, b) => {
        if(a.users.length < b.users.length) return 1
        if(a.users.length > b.users.length) return -1
        return 0
      })
      state.userWithCustomers = userWithCustomers
      distributeCustomers(state)
    }).addCase(assignCustomerDatabaseToUsersThunk.fulfilled, (state, action) => {
      
    })

    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes("CustomersDatabase"), (state) => {
      state.loading = true
    }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes("CustomersDatabase"), (state) => {
      state.loading = false
    })
  },
})

const distributeCustomers = async (state: CustomersDatabaseState): Promise<void> => {
  for (const i in state.userWithCustomers) {
    state.userWithCustomers[i].customers = []
  }
  const customers = state.rowCustomers.filter((c) => c.checked)
  const maxIndex = state.userWithCustomers.length - 1
  let i = 0
  for(const customerIndex in customers) {
    const c = customers[customerIndex]
    state.userWithCustomers[i].customers.push(c)
    if(i >= maxIndex) {i = 0;} else {i++}

  }
}
export const { checkCustomerDisabledAct } =CustomersDatabaseSlice.actions
export default CustomersDatabaseSlice.reducer
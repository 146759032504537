import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { Button, Divider, Grid, Paper } from "@mui/material"
import { PersonAddAlt1, Refresh } from "@mui/icons-material"
import { changeSearchStringAct, fetchUsersThunk, filterByOfficeChosenAct } from "../slice/user-list.slice"
import AppSelector from "../../../app/components/app-select"
import AppTextField from "../../../app/components/app-textfield"

export default function UserControlsCP() {
  const navigate  = useNavigate()
  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector((state:RootState) => state.login)
  const { officeIdFilter } = useAppSelector((state:RootState) => state.users)
  const { offices } = useAppSelector((state:RootState) => state.offices)
  const goToAddUser = () => {
    navigate("/dashboard/handle-user")
  }
  
  return(
    <>
      {currentUser!.level === 0 && 
        <Paper sx={{padding: 2, marginBottom: 2}}>
          <Grid container spacing={1}>
            <Grid item>
                <Button variant="contained" color="primary" onClick={goToAddUser}>Add User <PersonAddAlt1/></Button>
            </Grid>
            <Grid item>
              <Button color="warning" onClick={() => dispatch(fetchUsersThunk())} variant="outlined" fullWidth> <Refresh/> </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <AppSelector 
                options={offices} 
                value={officeIdFilter}
                name="office" 
                label="Oficina" 
                onChange={({name, val}) => dispatch(filterByOfficeChosenAct(val))}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AppTextField name="search_string" label="Buscador" onChange={(d) => dispatch(changeSearchStringAct(d.val))}/>
            </Grid>
          </Grid>

        </Paper>
      }
    </>
  )
}
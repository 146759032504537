import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { CustomerInterface } from "../../../../app/models/customer.interface";

export default function ExternalCustomerList({customers} : {customers: CustomerInterface[]}) {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((customer, index) => (
            <TableRow key={index}>
              <TableCell>{customer.name}</TableCell>
              <TableCell>{customer.phone}</TableCell>
              <TableCell>{customer.situation === null ? "NULL" : "NOT EMPTY"}</TableCell>
            </TableRow>
          ))} 
        </TableBody>
      </Table>
    </>
  )
}
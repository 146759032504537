import { Button } from "@mui/material";
import { CustomerInterface } from "../../../app/models/customer.interface";
import ExternalCustomerList from "./components/external-customers-list";
import ExternalCustomerForm from "./components/external-customer-form";
import { useAppDispatch } from "../../../app/hooks";
import { showFormExternalCustomerAct } from "./external-customers.slice";

export default function ExternalNumbersView({customers} : {customers: CustomerInterface[]}) {
  const dispatch = useAppDispatch()
  return (
    <>
      <ExternalCustomerForm/>
      <Button variant="outlined" onClick={() => dispatch(showFormExternalCustomerAct(true))}> ADD EXTERNAL NUMBER </Button>
      <ExternalCustomerList customers={customers} />
    </>
  )
}
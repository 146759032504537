/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ExpensesStateI } from "./models/handle-expenses.models"
import { addExpenseReq, fetchUserExpensesReq } from "../../app/services/expenses.service"
import { ExpenseInterface } from "../../app/models/expense-interface"

const initialState: ExpensesStateI = {
  expenses: [],
  loading: false,
  showFormModal: false,
  total: 0
}

export const fetchUserExpenseThunk = createAsyncThunk(
  "ExpensesSlice/fetchUserExpenses",
  async () => {
    const expenses = await fetchUserExpensesReq()
    return expenses
  },
)

export const addExpenseThunk = createAsyncThunk(
  "ExpensesSlice/addExpense",
  async (expense: ExpenseInterface) => {
    const addExpense = await addExpenseReq({expense})
    console.log({ addExpense })
    return addExpense
  },
)



export const ExpensesSlice = createSlice({
  name: "ExpensesSlice",
  initialState,
  reducers: {
    showModalFormExpenseAction: (state, action: PayloadAction<boolean>) => {
      state.showFormModal = action.payload
    },
    sumTotalExpensesAction: (state) => {
      state.total = state.expenses.reduce((acumulator, currentValue) => acumulator + currentValue.value, 0)
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchUserExpenseThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserExpenseThunk.fulfilled, (state, action: PayloadAction<ExpenseInterface[]>) => {
      state.expenses = action.payload
      state.loading = false
    })
    builder.addCase(addExpenseThunk.pending, (state) => {
      state.loading = true
      state.showFormModal = false
    })
    builder.addCase(addExpenseThunk.fulfilled, (state, action: PayloadAction<ExpenseInterface>) => {
      state.expenses.push(action.payload)
      state.loading = false
    })
  },
})

export const { showModalFormExpenseAction, sumTotalExpensesAction } = ExpensesSlice.actions

export default ExpensesSlice.reducer

import { Paper } from "@mui/material";
import TabHandler from "../../app/components/tab-handler";
import ExternalNumbersView from "./external-numbers/external-numbers.view";
import ExternalSituationsView from "./external-situations/external-situations.view";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect } from "react";
import { getxternalSitatuationThunk } from "./external-situations/external-situations.slice";
import { getExternalCustomersThunk } from "./external-numbers/external-customers.slice";

export default function ExternalsView() {
  const { situations } = useAppSelector((state) => state.externalSituations)
  const { customers } = useAppSelector((state) => state.externalClients)
  const dispatch = useAppDispatch() 
  useEffect(() => {
    dispatch(getxternalSitatuationThunk())
    dispatch(getExternalCustomersThunk())
  }, [])


  return (
    <>
      <Paper sx={{padding: 2}} elevation={2}>
        <TabHandler
          tabNames={["Numbers", "Situations"]}
          tabComponents={[
            <ExternalNumbersView customers={customers}/>,
            <ExternalSituationsView situations={situations} />
          ]}
         />
      </Paper>
    </>
  );
}
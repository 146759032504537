import { Close } from "@mui/icons-material";
import { Dialog, IconButton, DialogTitle, DialogContent, Grid, Divider, Button } from "@mui/material";
import AppTextField from "../../../../app/components/app-textfield";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { addExternalCustomerThunk, changeInputExternalCustomerAct, showFormExternalCustomerAct } from "../external-customers.slice";

export default function ExternalCustomerForm() {
  const { customerForm, showForm } = useAppSelector((state) => state.externalClients)
  const dispatch = useAppDispatch() 

  const changeInput = ({name, val} : {name: string, val: string}) => {
    dispatch(changeInputExternalCustomerAct({name, val}))
  }
  const submitForm = (e: any) => {
    e.preventDefault()
    dispatch(addExternalCustomerThunk({form: customerForm}))
  }
  return (
    <>
      <Dialog open={showForm}>
        <IconButton sx={{position: "absolute", top: 10, right: 10}} onClick={() => dispatch(showFormExternalCustomerAct(false))}> <Close/> </IconButton>
        <DialogTitle> Add Number </DialogTitle>
        <DialogContent sx={{minWidth: "600px"}}>
            <form onSubmit={submitForm}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AppTextField required label="Phone" value={customerForm.phone} name="phone" onChange={changeInput}/>
                </Grid>
                <Grid item xs={12}>
                  <AppTextField required label="Name" value={customerForm.name} name="name" onChange={changeInput}/>
                </Grid>
                <Grid item xs={12}>
                  <AppTextField  label="Last Name" value={customerForm.lastName} name="lastName" onChange={changeInput}/>
                </Grid>
                <Grid item xs={12}>
                  <AppTextField  label="Email" value={customerForm.email} name="email" onChange={changeInput}/>
                </Grid>
                <Divider className="divider"/>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth color="primary" type="submit"> SAVE </Button>
                </Grid>
              </Grid>

            </form>
        </DialogContent>
      </Dialog>
    </>
  ) 
}
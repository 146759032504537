import { CustomerWasTreatedType, NewCustomerFormI } from "../../features/customers/customers-list/customers.state";
import Api from "../axios";
import { CustomerInterface } from "../models/customer.interface";
import { CustomerRowCSVI } from "../models/customer-row-csv";
import { LeadsWithUsers } from "../models/leads-users-customer.interface";
import { PutUserToCustomerInterface } from "../models/put-user-to-customer";
import { CustomerCallActionsInterface } from "../models/customer-call-actions.interface";
import { FileUtils } from "../../utils/file.utils";
import { dateToInputDate } from "../../utils/date.utils";
import { CustomerCenterFilter } from "../../features/customers-center/components/customer-center.state";
import { ItemCustomerDatabase, RowCustomerDatabse, UserWithCustomersDatabaseType } from "../../features/customers-database/components/customer-disabled.state";
import { getCallsResume } from "../../utils/customer.utils";
import { CustomerStepsFormFilter } from "../../features/customer-steps-log/customer-step-log.state";

export async function addCustomerReq({customerForm} : {customerForm : NewCustomerFormI}): Promise<CustomerInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `customers`, data: customerForm})
    //console.log('addCustomerReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON addCustomerReq');
    console.error({error});
    throw error;
  }
}
export async function updateCustomerReq({customerData, customerId} : {customerData : CustomerInterface, customerId: string}): Promise<any>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `customers/update/${customerId}`, data: customerData})
    console.log('updateCustomerReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON updateCustomerReq');
    console.error({error});
    throw error;
  }
}
export async function getCustomersReq(): Promise<CustomerInterface[]>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `customers`})
    console.log('getCustomersReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getCustomersReq');
    console.error({error});
    throw error;
  }
}
export async function getActiveCustomersReq({office, lead, userId } : {office: string, lead: string, userId: string}): Promise<CustomerInterface[]>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `customers/filter-actives`, body: {office, lead, userId}})
    //console.log('getCustomersReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getCustomersReq');
    console.error({error});
    throw error;
  }
}
export async function filterCustomersAdminReq({value, dateStart, dateEnd, office, userAssigned, excludeDate, type } : CustomerCenterFilter): Promise<CustomerInterface[]>  {
  try {
    const api = Api.getInstance()
    const body = {search: value, dateEnd: dateToInputDate(dateEnd.toISOString()), dateStart: dateToInputDate(dateStart.toISOString()), office, userAssigned, excludeDate, type}
    
    const response = await api.post({path: `customers/filter-customers`, data: body}) 
    
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getCustomersReq');
    console.error({error});
    throw error;
  }
}
export async function getCustomersDatabaseReq(): Promise<RowCustomerDatabse[]>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `customers/get-customers-database`, })
    console.log('getCustomersDatabaseReq', {response});
    const { error, result } : {error: any, result: ItemCustomerDatabase[] } = response
    if(error == null) {
      const rows: RowCustomerDatabse[] = result.map((r: ItemCustomerDatabase) => ({
        checked: false,
        email: r.email,
        name: r.name,
        date: dateToInputDate(r.dateAssigned),
        nAssigned: r.historicalAssignations.length > 0 ? r.historicalAssignations[0].log.length : 0,
        nDisabled: r.historicalDisables.length > 0 ? r.historicalDisables[0].count : 0,
        phone: r.phone,
        _id: r._id,
        resumeCalls: getCallsResume(r),
        user: r.userCreator
      }) as RowCustomerDatabse)
      return rows
    } else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getCustomersDatabaseReq');
    console.error({error});
    throw error;
  }
}
export async function getCustomerById({customerId} : {customerId : string}): Promise<CustomerInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `customers/${customerId}`})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getCustomerById');
    console.error({error});
    throw error;
  }
}
export async function disableCustomerReq({customerId, motive} : {customerId : string, motive: string}): Promise<CustomerInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `customers/disable/${customerId}`, data: {motive}})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON disableCustomer');
    console.error({error});
    throw error;
  }
}
export async function uploadMultipleCustomerReq({customers} : {customers : CustomerRowCSVI[]}): Promise<CustomerInterface[]>  {
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `customers/customers-from-csv`, data: {customers}})
    console.log('uploadMultipleCustomer', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON uploadMultipleCustomer');
    console.error({error});
    throw error;
  }
}
export async function updateCustomersWithUsersReq({leadUsersMap, campaignId} : {leadUsersMap: {[leadId: string]: LeadsWithUsers}, campaignId: string}): Promise<boolean>  {
  try {
    const customers: PutUserToCustomerInterface[] = []
    for(const leadId in leadUsersMap) {
      const lead = leadUsersMap[leadId]
      const {officeCampaignId} = lead
      for(const userId in lead.users) {
        const user = lead.users[userId]
        for(const customer of user.customers) {
          customers.push({
            campaignId, 
            customerId: customer.customer!._id,
            leadId,
            officeCampaignId,
            userId
          })
        }
      }
    }

    const api = Api.getInstance()
    const response = await api.put({path: `customers/put-users-to-customers`, data: {customersData: customers}})
    console.log('METHOD', {response});
    const { error } = response
    if(error == null) {
      return true
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON METHOD');
    console.error({error});
    throw error;
  }
}
export async function logCustomerCallActionReq({customerId, callAction} : {customerId : string, callAction: number}): Promise<any>  {
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `customers/${customerId}/call-actions/${callAction}`})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON logCustomerCallAction');
    console.error({error});
    throw error;
  }
}
export async function logCustomerDontAnswerReq({customerId, file} : {customerId : string, file: any}): Promise<any>  {
  try {
    const api = Api.getInstance()
    const filesFormat = await file.map((file: any) => FileUtils.dataUrlToFile(file.src, file.name))
    const filesFormatted: Blob[] = await Promise.all(filesFormat)
  
    const formData = new FormData()
    for(const f of filesFormatted) {
      formData.append("image", f)
    }
    const response = await api.post({path: `customers/${customerId}/call-actions/dont-answer`, data: formData, isFormData: true})
 
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON logCustomerCallAction');
    console.error({error});
    throw error;
  }
}
export async function logCustomerAnswerReq({customerId, file} : {customerId : string, file: any}): Promise<any>  {
  try {
    const api = Api.getInstance()
    const filesFormat = await file.map((file: any) => FileUtils.dataUrlToFile(file.src, file.name))
    const filesFormatted: Blob[] = await Promise.all(filesFormat)
  
    const formData = new FormData()
    for(const f of filesFormatted) {
      formData.append("image", f)
    }
     const response = await api.post({path: `customers/${customerId}/call-actions/answer`, data: formData, isFormData: true})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON logCustomerCallAction');
    console.error({error});
    throw error;
  }
}
export async function customerAnswerReq({customerId} : {customerId : string}): Promise<CustomerInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `customers/${customerId}/answer`})
    console.log('customerAnswerReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON customerAnswerReq');
    console.error({error});
    throw error;
  }
}
export async function getCustomerCallActionsLogsReq({customerId} : {customerId : string}): Promise<CustomerCallActionsInterface[]>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `customers/${customerId}/calll-actions`})
    console.log('get', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON get');
    console.error({error});
    throw error;
  }
}
export async function getNCustomersByUser({userId} : {userId : string}): Promise<any>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `customers/n-users-by-user/${userId}`})
    console.log('getNCustomersByUser', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getNCustomersByUser');
    console.error({error});
    throw error;
  }
}
export async function assignCustomerDatabaseToUsersReq(param :  UserWithCustomersDatabaseType[]): Promise<any>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `customers/assign-customer-database-to-users`, data: param})
    console.log('assignCustomerDatabaseToUsersReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON assignCustomerDatabaseToUsersReq');
    console.error({error});
    throw error;
  }
}
export async function setUserToCustomerReq(data : {customerId: string, userId: string, officeId: string}): Promise<any>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `customers/set-user-to-customer`, data})
    console.log('setUserToCustomerReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON setUserToCustomerReq');
    console.error({error});
    throw error;
  }
}
export async function customerResumeReq({customerId} : {customerId : string}): Promise<any>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `customers/resume/${customerId}`})
    console.log('customerResumeReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON customerResumeReq');
    console.error({error});
    throw error;
  }
}
export async function checIfCustomerWasTreatedReq({customerId} : {customerId : string}): Promise<CustomerWasTreatedType>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `customers/check-if-was-treated/${customerId}`})
    console.log('checIfCustomerWasTreated', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON checIfCustomerWasTreated');
    console.error({error});
    throw error;
  }
}
export async function getCustomersByStepLogReq({filter} : {filter : CustomerStepsFormFilter}): Promise<any>{
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `customers/filter-by-steps`, data: filter})
    console.log('getCustomersByStepLogReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getCustomersByStepLogReq');
    console.error({error});
    throw error;
  }
}
export async function inactiveCustomerReq({customerId} : {customerId : string}): Promise<any>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `customers/inactive/${customerId}`})
    console.log('inactiveCustomerReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON inactiveCustomerReq');
    console.error({error});
    throw error;
  }
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { OfficesStateI } from "./offices-list.state"
import { enableOfficeReq, getOfficesReq, getOfficeUsersReq } from "../../../app/services/offices.service"
import { OfficeInterface } from "../../../app/models/office.inteface"
import UserInterface from "../../../app/models/user-interface"

const initialState: OfficesStateI = {
  offices: [],
  loading: false,
  gotOffices: false
}

export const usersForOfficeThunk = createAsyncThunk("OfficesSlice/usersForOfficeThunk", async ({ officeId } : { officeId : string}) =>  await getOfficeUsersReq({officeId}))

export const getOfficesThunk = createAsyncThunk("OfficesSlice/getOfficesThunk", async () =>  await getOfficesReq())

export const enableOfficeThunk = createAsyncThunk( "OfficesSlice/enableOfficeThunk", async (params: {officeId: string, enable: boolean}) => await enableOfficeReq(params))

export const OfficesSlice = createSlice({
  name: "OfficesSlice",
  initialState,
  reducers: {
    setLoadingOfficesAct: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    clearUsersForOfficeAct: (state) => {
      state.usersForOffice = undefined
    }
  },
  extraReducers(builder) {
    builder.addCase(getOfficesThunk.fulfilled, (state, action: PayloadAction<OfficeInterface[]>) => {
      state.offices = action.payload
      state.loading = false
      state.gotOffices = true
    }).addCase(usersForOfficeThunk.fulfilled, (state, action: PayloadAction<UserInterface[]>) => {
      state.usersForOffice = action.payload
      state.loading = false
    }).addCase(enableOfficeThunk.fulfilled, (state, action: PayloadAction<string>) => {
      const officeIndex = state.offices.findIndex((el) => el._id === action.payload)
      if(officeIndex !== -1) {
        state.offices[officeIndex].enable = !state.offices[officeIndex].enable
      }
    })

    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes("/OfficesSlice"), (state) => { 
      state.loading = true
     }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes("/OfficesSlice"), (state) => { 
      state.loading = false
     })
  },
})

export const { setLoadingOfficesAct, clearUsersForOfficeAct } = OfficesSlice.actions

export default OfficesSlice.reducer
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { logCustomerAnswerThuhk, logCustomerDontAnswerThuhk, setCustomerAsAnsweredThunk, setDialogCallUserAct } from "../customers.slice"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Image, ImageUploader } from "@martinmaffei95/image-uploader"
import { Close } from "@mui/icons-material"

export default function DialogCallCustomer() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {dialogCallUser, userAnswered} = useAppSelector((state) => state.customers)
  const {currentUser} = useAppSelector((state) => state.login)

  useEffect(() => {
    if(userAnswered === true) {
      closeDialog()
      navigate(`/dashboard/customer/${dialogCallUser!.customerId}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnswered, dialogCallUser])

  const pushNotAnswered = () => {
    //console.log(values!["raffleImgs"]);
    if(values!["raffleImgs"].length > 0) {
      closeDialog()
      dispatch(logCustomerDontAnswerThuhk({customerId: dialogCallUser!.customerId, file: values!["raffleImgs"]}))
    } else {
      showAlertAddImage()
    }
  }

  const closeDialog = () => dispatch(setDialogCallUserAct(undefined))
  
  const showAlertAddImage = () => alert("Debes agregar una imagen.")
  
  const pushAnswered = () => {
    if(values!["raffleImgs"].length > 0) {
      dispatch(logCustomerAnswerThuhk({customerId: dialogCallUser!.customerId, file: values!["raffleImgs"]}))
      dispatch(setCustomerAsAnsweredThunk({customerId: dialogCallUser!.customerId}))
    } else {
      showAlertAddImage()
    }
  }

  const [values, setValues] = useState<{ [name: string]: Image[] }>();

  const setFieldValue = (name: string, value: Image[]) => {
    setValues((state) => ({
      ...state,
      [name]: value,
    }));
  };

  return(
    <>
      <Dialog open={dialogCallUser !== undefined}>
        {currentUser?.level === 0 &&  <IconButton onClick={closeDialog} size="small" className="closeDialog"> <Close fontSize="small"/></IconButton> }
        <DialogTitle> Llamar usuario </DialogTitle>
        <DialogContent sx={{minWidth: "500px"}}>
          <DialogContentText>
            Numero de telefono: {dialogCallUser?.phone}
          </DialogContentText>
          <LinearProgress sx={{marginBlock: 2}} color="info" variant="indeterminate"/>
          <>
            <ImageUploader
              config={{
                colorScheme: "purple",
                inputConfig: {
                  multiple: true,
                  fieldName: "raffleImgs",
                  setFieldValue,
                }
              }}
            />
          </>
        </DialogContent>
        <DialogActions sx={{marginRight: 2, marginBottom: 2, display: 'flex', justifyContent: 'space-between'}}>
          <Button variant="contained" size="small" color="success"onClick={pushAnswered}> CONTESTO </Button>
          <Button variant="contained" size="small" color="error" onClick={pushNotAnswered}> NO CONTESTO </Button>

        </DialogActions>
      </Dialog>
    </>
  )
}
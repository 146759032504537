import AppSelector from "../../../app/components/app-select";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeTypeFilterAct } from "../customer-center.slice";

export default function CustomerCenterSelectorStatus() {
  const dispatch = useAppDispatch()
  const {filter} = useAppSelector((state) => state.customerCenter)  

  return(
    <>
      <AppSelector 
        label="Type"
        value={filter.type}
        onChange={(f) => dispatch(changeTypeFilterAct(f.val))}
        options={[
          {_id: 'all', name: 'All'},
          {_id: 'success', name: 'Sucess'},
          {_id: 'active', name: 'Active'},
          {_id: 'pending', name: 'Pending'},
          {_id: 'inactive', name: 'Disabled'},
          
        ]} 
      />
    </>
  )
}

//66171f9e59d691802e4000e6
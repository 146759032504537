import Api from "../axios"
import { UserLoginLogInterface } from "../models/user-login-log.interface"

export async function getLoginUserLogsReq({userId, end, start} : {userId: string, end: string, start: string}): Promise<UserLoginLogInterface[]> {
  try {
    const api = Api.getInstance()
    const getLogs = await api.get({ path: `users/userLogs/${userId}/${start}/${end}` })
    const { error } = getLogs
    if (error == null) {
      const { result } = getLogs
      return result
    } else {
      throw error
    }
  } catch (error) {
    console.error({ error })
    throw error
  }
}



import UserPercentagesControls from "../features/user-percentage/components/user-percentage-controls";
import UserPerncetageFormDialog from "../features/user-percentage/components/user-percentage-form-dialog";
import UsersPercentageList from "../features/user-percentage/components/user-percentage-list";

export default function UserPercentageView() {
  return (
    <>
      <UserPerncetageFormDialog/>
      <UserPercentagesControls/>
      <UsersPercentageList/>
    </>
  )
}
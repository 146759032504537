export function getCurrenDateUtil(): string {
  const now = new Date()
  const date = `${now.getFullYear()}-${(now.getUTCMonth() + 1)
    .toString()
    .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`
  return date
}

export function dateUTCToFriendly(date: string, justDate: boolean = false): string {
  const dateSplitted = date.split("T")
  const dates = dateSplitted[0]
  const time = dateSplitted[1]
  const timeSplitted = time.split(":")
  const dateFriend = dates.split("-").reverse().join("/");
  const timeFriend = `${timeSplitted[0]}:${ timeSplitted[1] }:${timeSplitted[2].split(".")[0]}`
  if(justDate) return dateFriend
  return `${dateFriend} ${timeFriend}`
}

export function dateToInputDate(date: string): string {
  return date.split("T")[0]
}

export function dateSplitted(d: string): { date: string; time: string } {
  const [date, time] = dateUTCToFriendly(d).split(" ")
  return { date, time }
}

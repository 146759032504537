import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { UserSesionLogInterface } from "./user-sessions.state"
import { getCurrenDateUtil } from "../../utils/date.utils"
import { getLoginUserLogsReq } from "../../app/services/user-logs.service"

const initialState: UserSesionLogInterface = {
  userId: "",
  loading: false,
  logs: [],
  form: {
    start: getCurrenDateUtil(),
    end: getCurrenDateUtil(),
  }
}

export const getUserSessionsLogsThunk = createAsyncThunk("userSessionsLogSlice/getUserSessionsLogs", async ({end, start, userId} : {start: string, end: string, userId: string}) => 
   await getLoginUserLogsReq({end, start, userId})
)

export const eventsGatewaySlice = createSlice({
  name: "userSessionsLogSlice",
  initialState,
  reducers: {
    setUserIdSessionsLogAct: (state, action: PayloadAction<string>)  => {
     state.userId = action.payload
    },
    updateFormInputUserLogsAct: (state, action: PayloadAction<{key: string, value: string}>) => {
      const { key, value } = action.payload
      state.form[key] = value 
    }
  },
  extraReducers(builder) {
    builder.addCase(getUserSessionsLogsThunk.fulfilled, (state, action) => {
      state.logs = action.payload
    })
    
    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes("userSessionsLogSlice"), (state) => {
      state.loading = true
    }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes("userSessionsLogSlice"), (state) => {
      state.loading = false
    })
  },
})

export const { setUserIdSessionsLogAct, updateFormInputUserLogsAct} = eventsGatewaySlice.actions

export default eventsGatewaySlice.reducer

import { Circle } from "@mui/icons-material";
import { Button, ButtonGroup, Table, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { blue, red, yellow } from "@mui/material/colors";
import { CustomerInterface } from "../../../../app/models/customer.interface";

export default function CustomersTableResume({customers} : {customers: CustomerInterface[]}) {
  const totalSuccess = customers.reduce((previous, current, i) => (current.status === 0 && current.answered  && (current.step as any).length > 0 ? previous + 1 : previous), 0)
  return (
    <TableContainer >
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell align="center"> 
              <Tooltip 
                title={`sin contestar ${customers.reduce((previous, current, i) => (current.status === 0 && !current.answered  && (current.step as any).length > 0 ? previous + 1 : previous), 0) }`}
              >
                <span>
                  <Circle  color="success"/> {' '} {totalSuccess}
                </span>
              </Tooltip>
            </TableCell>
            <TableCell align="center"> 
              <Circle sx={{color: yellow[500]}} /> {' '}
              {customers.reduce((previous, current, i) => (current.status === 0 && !current.answered ? previous + 1 : previous), 0) }
            </TableCell>
            <TableCell align="center"> 
              <Circle sx={{color: blue[500]}} /> {' '}
              {customers.reduce((previous, current, i) => (current.status === 0 && current.answered && (current.step as any).length === 0 ? previous + 1 : previous), 0) }
            </TableCell>
            <TableCell align="center"> 
              <Circle sx={{color: red[500]}} /> {' '}
              {customers.reduce((previous, current, i) => (current.status === 2 ? previous + 1 : previous), 0) }
            </TableCell>
            <TableCell align="center">  
              <ButtonGroup size="small" sx={{marginBottom: 1}}>
                <Button sx={{padding: 0.25}} size="small" variant="outlined" >Total: </Button>
                <Button sx={{padding: 0.25}} size="small" variant="outlined"> {customers.length}</Button>
              </ButtonGroup>  
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  )
}
import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import PeopleIcon from "@mui/icons-material/People"
import { Link } from "react-router-dom"
import {  AnalyticsOutlined,  AppsOutage,  BusinessOutlined, Campaign, Category, ChargingStationRounded, ChecklistRtl, ContactPhone, Dvr, ElectricalServices, FaceRetouchingNatural, Feed, HistoryEdu, ListAlt, PercentOutlined, PersonPinCircle, Route, SupportAgent, UploadFileSharp, WebStories, WifiTetheringErrorRoundedSharp } from "@mui/icons-material"
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { ErrorBar } from "recharts"
interface RouteItemI {
  to: string
  Icon: any
  title: string
}

export function MenuItems({onClick = ()=> {}} : {onClick: () => void}) {
  
  const {currentUser} = useAppSelector((state: RootState) => state.login)
  
  
  const mapRoutes: RouteItemI[] = [


    // { to: "/dashboard/create-raffle", Icon: <PeopleIcon />, title: "Agregar Sorteo" },
    // { to: "/dashboard/raffles", Icon: <PeopleIcon />, title: "Sorteos" },
    // { to: "/dashboard/create-card", Icon: <Margin />, title: "Crear Tarjeta" },

    { to: "/dashboard/numbers", Icon: <Dvr />, title: "Clientes" },
    { to: "/dashboard/imports-numbers", Icon: <UploadFileSharp />, title: "Importar data" },
    { to: "/dashboard/offices-list", Icon: <BusinessOutlined />, title: "Oficinas" },
    { to: "/dashboard/users", Icon: <PeopleIcon />, title: "Usuarios" },
    { to: "/dashboard/situations", Icon: <ListAlt />, title: "Situaciones" },
    { to: "/dashboard/campaign", Icon: <Campaign />, title: "Campaña" },
    { to: "/dashboard/reports", Icon: <AnalyticsOutlined />, title: "Reportes" },
    { to: "/dashboard/unstrusted-payments", Icon: <CreditScoreIcon />, title: "Validar pagos" },
    { to: "/dashboard/events-gateway", Icon: <ElectricalServices />, title: "Socket" },
    { to: "/dashboard/customers-center", Icon: <SupportAgent />, title: "Customer Center" },
    { to: "/dashboard/leads-auditory", Icon: <ChecklistRtl />, title: "Leads Auditory" },
    { to: "/dashboard/customers-database", Icon: <FaceRetouchingNatural />, title: "Customer Database" },
    { to: "/dashboard/steps", Icon: <Category />, title: "Pasos" },
    { to: "/dashboard/step-logs", Icon: <WebStories />, title: "Historial Pasos" },
    { to: "/dashboard/audit-resume", Icon: <HistoryEdu />, title: "Resumen Auditoria" },

    // { to: "/dashboard/face-auth-cam-face", Icon: <Category />, title: "Cam Face recognition" },
    // { to: "/dashboard/face-auth", Icon: <Category />, title: "Face Test" },

    //{ to: "/dashboard/expenses", Icon: <PriceChange />, title: "Gastos" },
    
    // { to: "/dashboard/capital-contribute", Icon: <AddBusiness />, title: "Aporte Capital" },
    // { to: "/dashboard/closure", Icon: <Analytics />, title: "Cuadre" },
    // { to: "/dashboard/cards-lists", Icon: <List />, title: "Lista" },
    // { to: "/dashboard/externals", Icon: <ThreePIcon />, title: "Externos" },
    { to: "/dashboard/user-actives-snap-shot", Icon: <AppsOutage />, title: "Activos historial" },
    { to: "/dashboard/users-percentage", Icon: <PercentOutlined />, title: "Porcentajes" },
    { to: "/dashboard/payment-route-template", Icon: <Route />, title: "Rutas de Pago" },
    { to: "/dashboard/users-with-not-customer", Icon: <WifiTetheringErrorRoundedSharp />, title: "Usuarios sin clientes" },
  ]
  
  const mapRoutesAssigner: RouteItemI[] = [
    { to: "/dashboard/users", Icon: <PeopleIcon />, title: "Usuarios" },
    { to: "/dashboard/numbers", Icon: <PeopleIcon />, title: "Clientes" },
    { to: "/dashboard/lead-campaign", Icon: <Campaign />, title: "Campaña" },
    { to: "/dashboard/reports", Icon: <AnalyticsOutlined />, title: "Reportes" },
  ]
  
  const mapRoutesLeader: RouteItemI[] = [
    { to: "/dashboard/users", Icon: <PeopleIcon />, title: "Usuarios" },
    { to: "/dashboard/numbers", Icon: <PeopleIcon />, title: "Clientes" },
    { to: "/dashboard/reports", Icon: <AnalyticsOutlined />, title: "Reportes" },
  ]

  const mapRoutesUser: RouteItemI[] = [
    { to: "/dashboard/numbers", Icon: <ContactPhone />, title: "Clientes" },
    //{ to: "/dashboard/lead-campaign", Icon: <Campaign />, title: "Campaña" },
  ]
  
  const mapRoutesOffice: RouteItemI[] = [
    { to: "/dashboard/log-arrive", Icon: <PersonPinCircle />, title: "Registro llegada" },
    //{ to: "/dashboard/lead-campaign", Icon: <Campaign />, title: "Campaña" },
  ]

  return (
    <React.Fragment>
        {(currentUser?.level === 0 ? mapRoutes : 
          currentUser?.level === 2 ? mapRoutesAssigner :  
          currentUser?.level === 3 ? mapRoutesLeader : 
          currentUser?.level === 6 ? mapRoutesOffice : mapRoutesUser).map((el, i) => {
        return (
          <Link key={"mainMenu" + i} to={el.to} onClick={onClick}>
            <ListItemButton>
              <ListItemIcon>{el.Icon}</ListItemIcon>
              <ListItemText primary={el.title} />
            </ListItemButton>
          </Link>
        )
      })}
    </React.Fragment>
  )
}

import { Button, Paper } from "@mui/material";
import ButtonReadExcelFile from "../button-read-excel-file";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { CloudSync } from "@mui/icons-material";
import { addCustomerToUsersThunk } from "../import-numbers.slice";
import DialogSuccessDataImported from "../../customer-view/components/dialog-success-data-imported";

export default function PreviewDataControls() {
  const dispatch = useAppDispatch()
  const { uploadedData, leadUsersMap, currentCampaign, currentCampaignGot} = useAppSelector((state: RootState) => state.importNumbers)
  return (
    <>
      <DialogSuccessDataImported/>
      <Paper sx={{padding: 2, marginTop: 2}} elevation={4}>
        {currentCampaignGot === true && currentCampaign && <ButtonReadExcelFile/>}
        <Button variant="contained" disabled={uploadedData.length === 0} color="success" sx={{marginLeft: 2}}
          onClick={() => dispatch(addCustomerToUsersThunk({campaign: currentCampaign!, leadUserMap: leadUsersMap}))}
        > 
          SINCRONIZAR <CloudSync />
        </Button>
      </Paper>
    </>
  );
}
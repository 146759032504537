import { Card , IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect } from "react";
import { getStepsThunk, setStepIdForEditAct, showFormStepAct, updateInputStepFormAct } from "../steps.slice";
import { Edit } from "@mui/icons-material";
import { StepType } from "../../../app/models/step.type";
import LoadingIndicator from "../../../app/components/loading-indicator";

export default function StepsList() {
  const { steps, loading } = useAppSelector((state) => state.steps)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getStepsThunk())
  }, [])

  const editStep = (step: StepType) => { 
    dispatch(showFormStepAct(true))
    dispatch(updateInputStepFormAct({name: 'order', value: step.order}))
    dispatch(updateInputStepFormAct({name: 'title', value: step.title}))
    dispatch(setStepIdForEditAct(step._id))
  }
  return (
    <>
      <LoadingIndicator open={loading}/>
     <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>N</TableCell>
                <TableCell>Title</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {steps.map((step, index) => 
                <TableRow key={index}>
                  <TableCell>{step.order}</TableCell>
                  <TableCell>{step.title}</TableCell>
                  <TableCell> <IconButton color="primary" size="small"> 
                    <Edit onClick={() => editStep(step)} fontSize="small"/> </IconButton> 
                  </TableCell>
                </TableRow>
              )
              }
            </TableBody>
          </Table>

        </TableContainer>
      </Card> 
    </>
  )
}
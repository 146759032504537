import Api from "../axios";
import { CampaignInterface } from "../models/campaign.interface";
import { OfficeCampaignInterface } from "../models/office-campaign.interface";

export async function getCurrentCampaignReq(): Promise<{campaign: CampaignInterface, officesCampaigns: OfficeCampaignInterface[]}>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `campaigns/current`})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON METHOD');
    console.error({error});
    throw error;
  }
}

export async function createCampaignReq(): Promise<CampaignInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `campaigns`, data: {}})
    console.log('create', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON create');
    console.error({error});
    throw error;
  }
}

export async function getCampaignForLeadReq(): Promise<{campaign?: CampaignInterface, officeCampaign?: OfficeCampaignInterface}>  {
  try {
    const api = Api.getInstance()
    const campaignForLead = await api.get({path: `campaigns/campaign-for-lead`})
    const { error } = campaignForLead
    if(error == null) {
      return campaignForLead.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getCampaignForLead');
    console.error({error});
    throw error;
  }
}

export async function addUsersToCampaignReq({users} : {users : string[]}): Promise<OfficeCampaignInterface>  {
  try {
    const api = Api.getInstance()

    const response = await api.post({path: `campaigns/addUsers`, data: {users} })
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON addUsersToCampaign');
    console.error({error});
    throw error;
  }
}

export async function addUsersDatabase({users} : {users : string[]}): Promise<OfficeCampaignInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `campaigns/addUsersDatabase`, data: {users} })
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON addUsersToCampaign');
    console.error({error});
    throw error;
  }
}

export async function switchCampaignReq({enable} : {enable : boolean}): Promise<CampaignInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `campaigns/current/${enable}`, data: {}})
    console.log('switchCampaignReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON switchCampaignReq');
    console.error({error});
    throw error;
  }
}

export async function toggleSingleUserFromCampaignReq({userId, officeCampaignId} : {userId : string, officeCampaignId: string}): Promise<OfficeCampaignInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.put({path: `campaigns/toggle-single-user-to-campaign`, data: {userId, officeCampaignId}})
    console.log('toggleSingleUserFromCampaign', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON toggleSingleUserFromCampaign');
    console.error({error});
    throw error;
  }
}
export async function toggleSingleUserFromCampaignDatabaseReq({userId, officeCampaignId} : {userId : string, officeCampaignId: string}): Promise<OfficeCampaignInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.put({path: `campaigns/toggle-single-user-to-campaign-database`, data: {userId, officeCampaignId}})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON toggleSingleUserFromCampaign');
    console.error({error});
    throw error;
  }
}

export async function toggleAllowModifyOfficeCampaignReq({officeId, allow, officeIndex} : {officeId : string, allow: boolean, officeIndex: number, }): Promise<{officeCampaign: OfficeCampaignInterface, officeIndex: number}>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `campaigns/toggle-allow-modify-office-users-campaign/${officeId}/${allow}`})
    const { error } = response
    if(error == null) {
      return {officeCampaign: response.result, officeIndex}
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON toggleAllowModifyOfficeCampaing');
    console.error({error});
    throw error;
  }
}

export async function toggleAllowModifyOfficeCampaignDatabaseReq({officeId, allow, officeIndex} : {officeId : string, allow: boolean, officeIndex: number, }): Promise<{officeCampaign: OfficeCampaignInterface, officeIndex: number}>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `campaigns/toggle-allow-modify-office-users-campaign-database/${officeId}/${allow}`})
    console.log('toggleAllowModifyOfficeCampaing', {response});
    const { error } = response
    if(error == null) {
      return {officeCampaign: response.result, officeIndex}
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON toggleAllowModifyOfficeCampaing');
    console.error({error});
    throw error;
  }
}

export async function toggleEnableCampaignDatabaseReq({campaignId, enable} : {campaignId : string, enable: boolean}): Promise<CampaignInterface>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `campaigns/toggle-enable-database-campaign/${campaignId}/${enable}`})
    console.log('toggleEnableCampaignDatabase', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON toggleEnable  12CampaignDatabase');
    console.error({error});
    throw error;
  }
}
import { Button } from "@mui/material";
import { useAppDispatch } from "../../../app/hooks";
import { showAddFormExternalSituationAct } from "./external-situations.slice";
import ExternalSituationForm from "./components/external-situation-form";
import { SituationInterface } from "../../../app/models/situation-interface";
import ExternalSituationsList from "./components/external-situations-list";

export default function ExternalSituationsView({situations} : {situations: SituationInterface[]}) {
  const dispatch = useAppDispatch()
  return (
    <>
      <ExternalSituationForm/>
      <div>
        <Button variant="contained" onClick={() => dispatch(showAddFormExternalSituationAct(true))}> ADD SITUATION </Button>
      </div>
      <ExternalSituationsList situations={situations}/>
    </>
  );
}
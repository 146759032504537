import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Grid, List, ListItem, Paper, Typography, createTheme } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getOfficesCampaignsDisabledThunk } from "../customers-database.slice";
import { getCurrentCampaignThunk } from "../../campaigns/current-campaign/current-campaign.slice";
import { ThemeProvider } from "@emotion/react";

const theme = createTheme({
  components: {
    MuiCardHeader: {styleOverrides: {subheader: {padding: 0},}},
    MuiAccordionSummary: {styleOverrides: {root: {padding: 0, paddingInline: 10, minHeight: 30}} },
    MuiAccordionDetails: {styleOverrides: {root: {padding: 0, paddingInline: 10},}},
    MuiButton: {styleOverrides: {root: {padding: 0, margin: 0, minHeight: 30}}},
  },
})

export default function CustomersDisabledOfficesCampaigns() {
  const dispatch = useAppDispatch()
  const { officesCampaignsWithUsersWithCustomers, userWithCustomers } = useAppSelector((state) => state.customerDatabase) 
  useEffect(() => {
    dispatch(getOfficesCampaignsDisabledThunk())
    dispatch(getCurrentCampaignThunk())
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <Card sx={{marginTop: 2}}>
        <Typography sx={{padding: 1}}> Office Campaigns </Typography>
        <Grid container spacing={1}>
          {officesCampaignsWithUsersWithCustomers.map((o, i) => 
            <Grid item xs={4} key={o._id}>
              <Card component={Paper}>
                <CardHeader subheader={`${o.lead.name} (${o.lead.email})`}/>
                <CardContent>
                  {o.users.map((u, i) => {
                    const indexFromState = userWithCustomers.findIndex((r) => r._id === u._id)
                    const customers = indexFromState !== -1 ? userWithCustomers[indexFromState].customers : []
                    return (<Accordion key={u._id+o._id}>
                      <AccordionSummary disabled={customers.length === 0}> {u.lastName} ({u.email}) </AccordionSummary>
                      <AccordionDetails>
                        <List disablePadding>
                          {customers.map((c, i) => 
                            <ListItem key={c._id} disablePadding>
                              <Typography variant="caption" >{c.name} - {c.phone}</Typography>
                            </ListItem>
                            )}

                        </List>
                      </AccordionDetails>
                    </Accordion>)
                  } 
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Card>
    </ThemeProvider>
  )
}
import { SituationFormI } from "../../features/customer-situations/client-situations/client-situations.state";
import { NewCustomerFormI } from "../../features/customers/customers-list/customers.state";
import Api from "../axios";
import { CustomerInterface } from "../models/customer.interface";
import { SituationInterface } from "../models/situation-interface";

export async function addExternalSitatuationReq({situationForm} : {situationForm : SituationFormI}): Promise<SituationInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `externals/situation`, data: situationForm})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON addSitatuation');
    console.error({error});
    throw error;
  }
}

export async function getExternalSituationsReq(): Promise<SituationInterface[]>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `externals/situations`})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getSituations');
    console.error({error});
    throw error;
  }
}

export async function updateExternalSitatuationReq({situationForm, situationId} : {situationForm : SituationFormI, situationId: string}): Promise<SituationInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.put({path: `externals/situations/${situationId}`, data: situationForm})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON addSitatuation');
    console.error({error});
    throw error;
  }
}

export async function addExternalCustomerReq({customerForm} : {customerForm : NewCustomerFormI}): Promise<CustomerInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `external-customers`, data: customerForm})
    //console.log('addCustomerReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON addCustomerReq');
    console.error({error});
    throw error;
  }
}

export async function getExternalCustomersReq(): Promise<any>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `external-customers`})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON METHOD');
    console.error({error});
    throw error;
  }
}


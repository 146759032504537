import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch } from "../../../../app/hooks";
import { Edit } from "@mui/icons-material";
import parse from 'html-react-parser'
import { SituationInterface } from "../../../../app/models/situation-interface";
import { setExternalSituationForEditAct } from "../external-situations.slice";

export default function ExternalSituationsList({situations} : {situations: SituationInterface[]}) {
  const dispatch = useAppDispatch()

  
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell>Titulo</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Texto</TableCell>
              <TableCell>Text</TableCell>
              <TableCell> <Edit/> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {situations.map((el, i) => {
              return(
              <TableRow key={el._id}>
                <TableCell>{el.order}</TableCell>
                <TableCell>{el.title}</TableCell>
                <TableCell>{el.titleEn}</TableCell>
                <TableCell>{parse(el.description)}</TableCell>
                <TableCell>{parse(el.descriptionEn)}</TableCell>
                <TableCell> <IconButton onClick={() => dispatch(setExternalSituationForEditAct(el))}> <Edit/> </IconButton> </TableCell>
              </TableRow>
              )
            })}
          </TableBody>
        </Table>

      </TableContainer>
    </>
  )
}
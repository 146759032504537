import { Button, Paper } from "@mui/material";
import { useAppDispatch } from "../../../app/hooks";
import { showPercentageDialogAct } from "../slice/user-percentage.slice";

export default function UserPercentagesControls() {
  const dispatch = useAppDispatch()
  return (
    <>
      <Paper sx={{padding: 1}}>
        <Button variant="contained" onClick={() => dispatch(showPercentageDialogAct({user: ""})) }> ADD PERCENTAGE </Button>
      </Paper>
    </>
  )
}
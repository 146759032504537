import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Close } from "@mui/icons-material";
import AppTextField from "../../../app/components/app-textfield";
import { createStepThunk, showFormStepAct, updateInputStepFormAct, updateStepThunk } from "../steps.slice";

export default function DialogStepForm() {
  const {showForm, stepToEdit, stepForm} = useAppSelector((state) => state.steps)
  const dispatch = useAppDispatch()
  const closeDialog = () => dispatch(showFormStepAct(false))

  const changeInput = (data: {name: string, val: string}) => {
    dispatch(updateInputStepFormAct({name: data.name, value: data.val}))
  }

  const sendStep = () => {
    if(stepToEdit === undefined)  {
      dispatch(createStepThunk(stepForm))
    } else {
      dispatch(updateStepThunk({ data: stepForm, stepId: stepToEdit}))

    }
  }
  return (
    <>
      <Dialog open={showForm}>
        <IconButton className="closeDialog" onClick={closeDialog}> <Close/> </IconButton>
        <DialogTitle sx={{marginRight: 3}}>
          {stepToEdit ? 'Edit Step' : 'Add Step'}
        </DialogTitle>
        <DialogContent sx={{minWidth: '400px'}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AppTextField type="number" name='order' label="Paso" value={stepForm.order} onChange={changeInput}/>
            </Grid>
            <Grid item xs={12}>
              <AppTextField name="title"  label="Titulo" value={stepForm.title} onChange={changeInput}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}> CANCELAR </Button>
          <Button variant="outlined" onClick={sendStep} color="success"> AGREAR </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { InputLabelValueType, PaymentRouteTemplateState } from "./pay-route-template.state";
import { TypePercentageEnum } from "../../user-percentage/slice/users-percentage.state";
const labelInputEmpty: InputLabelValueType = {label: "", value: ""}
const initialForm = {
  title: "",
  collector: labelInputEmpty,
  worker: labelInputEmpty,
  leadWorker: labelInputEmpty,
  officeLead: labelInputEmpty,
  sublead: [],
  partner: [],
}
const initialState: PaymentRouteTemplateState = {
  loading: false,
  typePercentageToPick: TypePercentageEnum.empty,
  showDialogPickPercentage: false,
  paymentRouteTemplateForm: initialForm
}
export const thunkAsync = createAsyncThunk( "PaymentRouteTemplate/fetchCount", async (amount: number) => { 
    const response = await setTimeout(() => {}, 2000);
    return response
  },
)
export const PaymentRouteTemplateSlice = createSlice({
  name: "PaymentRouteTemplate",
  initialState,
  reducers: {
    changeTitleTemplateFormAct: (state, action: PayloadAction<string>) => {
      state.paymentRouteTemplateForm.title = action.payload
    },
    showDialogPickPercentageAct: (state, action: PayloadAction<boolean>) => {
      state.showDialogPickPercentage = action.payload
      if(!action.payload) state.typePercentageToPick = TypePercentageEnum.empty
    },
    setTypePercentageToPickAct: (state, action: PayloadAction<TypePercentageEnum>) => {      
      state.typePercentageToPick = action.payload
    },
    changeInputPayTemplateAct: (state, action: PayloadAction<{key: string, value: InputLabelValueType | InputLabelValueType[]}>) => {
      state.paymentRouteTemplateForm[action.payload.key] = action.payload.value
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(incrementAsync.fulfilled, (state, action) => {
    // })
    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes("PaymentRouteTemplate"), (state) => {
      state.loading = true
    }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes("PaymentRouteTemplate"), (state) => {
      state.loading = false
    })
  },
})
export const { changeTitleTemplateFormAct, showDialogPickPercentageAct, setTypePercentageToPickAct, changeInputPayTemplateAct } =PaymentRouteTemplateSlice.actions
export default PaymentRouteTemplateSlice.reducer
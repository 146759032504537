import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Close } from "@mui/icons-material";
import { changeInputPayTemplateAct, showDialogPickPercentageAct } from "../slice/pay-route-template.slice";
import AppSelector from "../../../app/components/app-select";
import { TypePercentageEnum } from "../../user-percentage/slice/users-percentage.state";
import { useEffect } from "react";
import { filterUserPercentageThunk } from "../../user-percentage/slice/user-percentage.slice";

export default function PaymentRouteDialogPickPercentage() {
  const dispatch = useAppDispatch()
  const {showDialogPickPercentage, typePercentageToPick, paymentRouteTemplateForm} = useAppSelector((state) => state.paymentRouteTemplate)
  const {usersPercentageFiltered} = useAppSelector((state) => state.usersPercentage)
  const closeDialog = () => dispatch(showDialogPickPercentageAct(false))

  const optionsUserPercentage = usersPercentageFiltered.map((p) => ({_id: p._id, name: `${p.percentage}%|${p.user.email}`})) 

  useEffect(() => {
    if(typePercentageToPick.toString() !== "")  dispatch(filterUserPercentageThunk({type:typePercentageToPick}))
  }, [typePercentageToPick, dispatch])

  const changeSelector = ({val, name} : {name: string, val: any}) => {
    //console.log({val, name, isMultiple});
    if(isMultiple) {

    } else {
      const index = usersPercentageFiltered.findIndex((p) => p._id === val)
      if(index !== -1) {
        const value = {label: `${usersPercentageFiltered[index].percentage}%|${usersPercentageFiltered[index].user.email}`, value: usersPercentageFiltered[index]._id}
        dispatch((changeInputPayTemplateAct({key: name, value: value})))
      } else {
        dispatch((changeInputPayTemplateAct({key: name, value: {label: "", value: ""}})))
      }
    }

  }
  

  const cancelDialog = () => {
    //closeDialog()
    dispatch((changeInputPayTemplateAct({key: typePercentageToPick.toString(), value: isMultiple ? [] : {label: "", value: ""}})))
  }

  const typePercentageMultiples = [TypePercentageEnum.subLead, TypePercentageEnum.partenr];
  const isMultiple = typePercentageMultiples.includes(typePercentageToPick);

  const value = typePercentageToPick && typePercentageToPick.toString() !== "" ? isMultiple ? paymentRouteTemplateForm[typePercentageToPick.toString()] : paymentRouteTemplateForm[typePercentageToPick.toString()].value : '';

  console.log({isMultiple, value});
  
  return (
    <>
      <Dialog open={showDialogPickPercentage}>
        <IconButton onClick={closeDialog} className="closeDialog" > <Close /> </IconButton>
        <DialogTitle> Pick {typePercentageToPick} percentage</DialogTitle>
        <DialogContent sx={{minWidth: 500}}>
          <AppSelector 
            name={typePercentageToPick.toString()}
            label="Usuario" 
            value={value} 
            options={optionsUserPercentage} 
            onChange={changeSelector} 
            multiple={isMultiple} 
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="success" onClick={closeDialog}> ACEPTAR </Button>
          <Button variant="outlined" color="error" onClick={cancelDialog} > CANCELAR </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
import { Accordion, AccordionSummary, Grid, List, ListItem, ListItemText, ListSubheader, Paper } from "@mui/material"
import { useAppSelector } from "../../../../app/hooks"
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import React from "react";


export default function UsersAvailableForCampaigns() {
  const { officesCampaigns, leadsId, leadUsersMap  } = useAppSelector(state => state.importNumbers)  

  return (

    <>
        {Object.keys(leadUsersMap).length > 0 && <Grid container spacing={2}>
          {officesCampaigns.map(officeCampaign => {
            const leadId = (officeCampaign.user as any)._id
            const key = `officeCampaign${officeCampaign._id}`
            return (
              leadsId[leadId] !== undefined  ? <Grid item xs={12} md={6} lg={4} key={key} marginTop={2}>
                <List component={Paper} elevation={4} subheader={<ListSubheader> {leadsId[leadId].name!} </ListSubheader>}>
                  {officeCampaign.users.map(el => {
                    const user = el as any                    
                    return (
                      <ListItem key={`${user._id}at_campaign`}>
                        <Accordion sx={{width: "100%"}} disabled={!leadUsersMap[leadId].users[user._id as string] === undefined && leadUsersMap[leadId].users[user._id as string].customers.length === 0}>
                          <AccordionSummary expandIcon={<ReduceCapacityIcon/>}>{user.name} ({user.email})</AccordionSummary>
                          <List>
                            {leadUsersMap[leadId].users[user._id as string] && leadUsersMap[leadId].users[user._id as string].customers.map((customer, i) => (
                              <ListItem key={customer.customer!._id}>
                                <ListItemText primary={customer.name} /> 
                                <ListItemText primary={customer.phone} /> 
                              </ListItem>
                            ))}
                          </List>
                        </Accordion>
                      </ListItem>
                    )
                  })}
                </List>
              </Grid> : <React.Fragment key={key}></React.Fragment>
            )
          })}
        </Grid>}
    </>
  )
}
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material"
import LoadingIndicator from "../../app/components/loading-indicator"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { Close, Download } from "@mui/icons-material"
import AppTextField from "../../app/components/app-textfield"
import UserSignInsTable from "./components/user-signins-table"
import { getUserSessionsLogsThunk, setUserIdSessionsLogAct, updateFormInputUserLogsAct } from "./user-sessions.slice"

export default function UserSessionsDialog() {
  const dispatch = useAppDispatch()
  const { userId, form, loading} = useAppSelector((state: RootState) => state.userSessionLogs)
  const {start, end} = form
  const changeInput = ({name, val} : {name: string, val: string}) => {
    dispatch(updateFormInputUserLogsAct({key: name, value: val}))
  }
  return (
    <>
      <LoadingIndicator open={loading}/>
      <Dialog open={userId !== ''}>
          <IconButton onClick={() => dispatch(setUserIdSessionsLogAct(""))} className="closeDialog"> <Close/> </IconButton>
          <DialogTitle>User sign in logs</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <AppTextField name="start" label="Start Date" type="date" value={start} onChange={changeInput}/>
              </Grid>
              <Grid item xs={5}>
                <AppTextField name="start" label="End Date" type="date" value={end} onChange={changeInput}/>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" onClick={() => dispatch(getUserSessionsLogsThunk({end, start, userId}))}> <Download /></Button>
              </Grid>
            </Grid>
            <UserSignInsTable/>
          </DialogContent>
      </Dialog>
    </>
  )
}
import { useAppSelector } from "../../../app/hooks"
import LoadingIndicator from "../../../app/components/loading-indicator"
import { RootState } from "../../../app/store"
import { Button, Card, Paper, Typography } from "@mui/material"
import OfficesTableList from "./components/offices-table-list"
import UsersForOfficeDialog from "./components/users-for-office-modal"
import { Add } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"

export default function OfficesListView(){
  const loading = useAppSelector((state: RootState) => state.offices.loading)
  const navigate = useNavigate()
  
  return (
    <>
      <LoadingIndicator open={loading}  />
      <UsersForOfficeDialog/>
      <Paper>
        
        <Card sx={{padding: 1}}>
          <Typography variant="h6" marginBottom={2}>Campuses</Typography>
          <Button size="small" variant="contained" onClick={() => navigate("/dashboard/handle-office")}>Add Campus <Add/> </Button>
        </Card>
        <Card sx={{padding: 1}}>
          <OfficesTableList/>
        </Card>

      </Paper>
    </>
  )
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ExternalCustomersState } from "./external-customers.state"
import { addExternalCustomerReq, getExternalCustomersReq } from "../../../app/services/externals.service"
import { NewCustomerFormI } from "../../customers/customers-list/customers.state"

const newCustomerForm: NewCustomerFormI = {
  name: "",
  lastName: "",
  phone: "",
  email: "",
  address: "",
  office: "",
  userAssigned: ""
}

const initialState: ExternalCustomersState = {
  loading: false,
  customers: [],
  customersOriginal: [],
  customerForm: newCustomerForm,
  showForm: false
}
export const addExternalCustomerThunk = createAsyncThunk("ExternalCustomers/addExternalCustomerThunk", async ({ form } : { form : NewCustomerFormI}) => {
  return await addExternalCustomerReq({customerForm: form})
})

export const getExternalCustomersThunk = createAsyncThunk("ExternalCustomers/getExternalCustomersThunk", async () => {
  return await getExternalCustomersReq()
})

export const ExternalClientsSlice = createSlice({
  name: "ExternalCustomers",
  initialState,
  reducers: {
    showFormExternalCustomerAct: (state, action: PayloadAction<boolean>) => {
      state.showForm = action.payload
     },
     changeInputExternalCustomerAct: (state, action: PayloadAction<{name: string, val: string}>) => { 
      state.customerForm[action.payload.name!] = action.payload.val
     }
  },
  extraReducers(builder) {
    builder.addCase(addExternalCustomerThunk.fulfilled, (state, action) => {
      state.customers.push(action.payload)
      state.customersOriginal.push(action.payload)
      state.showForm = false
      state.customerForm = newCustomerForm
    }).addCase(getExternalCustomersThunk.fulfilled, (state, action) => {
      state.customers = action.payload
      state.customersOriginal = action.payload
    })

    builder.addMatcher((action) => {
      return action.type.endsWith("/pending") && action.type.includes("ExternalCustomers")
    }, (state, action) => {
      state.loading = true
    }).addMatcher((action) => {
      return action.type.endsWith("/fullfiled") && action.type.includes("ExternalCustomers")
    }, (state, action) => {
      state.loading = false
    })
  },
})

export const { showFormExternalCustomerAct, changeInputExternalCustomerAct } = ExternalClientsSlice.actions

export default ExternalClientsSlice.reducer
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LoadingIndicator from "../../../app/components/loading-indicator";
import { useEffect } from "react";
import { fetchUserPercentagesThunk } from "../slice/user-percentage.slice";
import { Person2, Settings } from "@mui/icons-material";
import { ThemeProvider } from "@emotion/react";
import { themeCondense } from "../../../app/themes/theme-condense";

export default function UsersPercentageList() {
  const dispatch = useAppDispatch()
  const { loading, usersPercentageRows  } = useAppSelector((state) => state.usersPercentage)

  useEffect(() => {
    dispatch(fetchUserPercentagesThunk())
  }, [])
  return (
    <ThemeProvider theme={themeCondense}> 
      <LoadingIndicator open={loading}/>
      <Paper sx={{padding: 2, marginBottom: 1}}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> <Person2/> </TableCell>
                <TableCell>Type</TableCell>
                <TableCell>&</TableCell>
                <TableCell> <Settings/> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersPercentageRows.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row.user.name}</TableCell>
                  <TableCell>{row.typePercentage}</TableCell>
                  <TableCell>{row.percentage}</TableCell>
                  <TableCell>
                    <IconButton> <Settings/> </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </ThemeProvider>
  )
}
import { Done,  ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import AppDateRangeSelector from "../../../app/components/app-date-range-selector";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeFilterCustomersResumeOfficeAct, getOfficeCustomersResumeThunk } from "../office-dashboard.slice";
import LoadingIndicator from "../../../app/components/loading-indicator";
import TabHandler from "../../../app/components/tab-handler";
import { dateToInputDate } from "../../../utils/date.utils";
import { useParams } from "react-router-dom";
import OfficeDashboardCustomerResumeTable from "./office-dashboard-customer-resume-table";

export default function OfficeDashboarCustomersResume() {
  const {customersResume, customersResumeFilter , loading} = useAppSelector(state => state.officeDashboard)
  const {dateEnd, dateStart} = customersResumeFilter
  const dispatch = useAppDispatch()
  const {officeId} = useParams()

  const getCustomersResume = () => {
    dispatch(getOfficeCustomersResumeThunk({officeId: officeId!, dateStart: dateToInputDate(dateStart.toISOString()), dateEnd: dateToInputDate(dateEnd.toISOString())}))
  }
  return(
    <>
      <LoadingIndicator open={loading}/>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreOutlined/>}>
          <Typography variant="h6">Customers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <AppDateRangeSelector id="officeDashboardCustomerResume" dateEnd={dateEnd} dateStart={dateStart}
                onChange={({dateStart, dateEnd}) => dispatch(changeFilterCustomersResumeOfficeAct({dateStart, dateEnd}))}
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="outlined" size="small" onClick={getCustomersResume}> <Done/> </Button>
            </Grid>
          </Grid>
          <TabHandler
            tabNames={["Activos", "Pendientes", "Inactivos"]}
            tabComponents={[
              <OfficeDashboardCustomerResumeTable rows={customersResume.filter((c) => c.answered === true && c.status === 0)} />,
              <OfficeDashboardCustomerResumeTable rows={customersResume.filter((c) => c.answered === false && c.status === 0)} />,
              <OfficeDashboardCustomerResumeTable rows={customersResume.filter((c) => c.status > 0)} />
            ]}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
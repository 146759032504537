import Api from "../axios"
import { ExpenseInterface } from "../models/expense-interface"

const api = new Api()
export async function fetchUserExpensesReq() {
  try {
    const fetchCards = await api.get({ path: "expenses/get-user-expenses" })
    const { error } = fetchCards
    if (error === null) {
      const { result } = fetchCards
      return result
    } else {
      throw error
    }
  } catch (error) {
    throw error
  }
}

export async function addExpenseReq({ expense }: { expense: ExpenseInterface }) {
  try {
    const addExpense = await api.post({ path: "expenses/add-expense", data: expense, })
    console.log({ addExpense })
    const { error } = addExpense
    if (error === null) {
      const { result } = addExpense
      return result
    } else {
      throw error
    }
  } catch (error) {
    throw error
  }
}

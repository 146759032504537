import { Button, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material"
import DialogConfirmImageFeePayment from "./dialog-confirm-image-fee-payment"
import DialogConfirmPayedFeePayment from "./dialog-confirm-payed-fee-payment"
import { Check, Image, Paid } from "@mui/icons-material"
import ImagePreviewDialog from "../../../../app/components/image-preview-dialog"
import { useAppSelector, useAppDispatch } from "../../../../app/hooks"
import { PaymentRequestI } from "../../../../app/models/fee-payment-result-inteface"
import { RootState } from "../../../../app/store"
import { dateUTCToFriendly } from "../../../../utils/date.utils"
import { numberToCurrency } from "../../../../utils/numbers.utils"
import { setPaymentImagePreviewAct, setFeePaymentConfirmImageDialogAct, setFeePaymentConfirmPayedDialogAct } from "../reports.slice"

export default function ResultsPaymentsCP() {
  const {filter, feePayments, imagePreviewPayment} = useAppSelector((state: RootState) => state.reports)
  const dispatch = useAppDispatch()
  const resolveCustomer = (customer: any): string => {
    if(customer.length === 0) return ""
    return `${customer[0].name} ${customer[0].phone}`
  }

  const resolveUser = (payment: PaymentRequestI[]): string => {
    if(payment.length === 0) return ""
    if(payment[0].user.length === 0) return ""

    return `${payment[0].user[0].name}`
  }

  return(
      <>
         {filter.type === "payments-made" && feePayments !== undefined && <>
        <ImagePreviewDialog image={imagePreviewPayment} onClose={() => dispatch(setPaymentImagePreviewAct(""))}/>
        <DialogConfirmImageFeePayment/>
        <DialogConfirmPayedFeePayment/>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Value</TableCell>
                <TableCell><Image/></TableCell>
                <TableCell><Check/></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feePayments.map((pay, i) => (
                <TableRow key={i}>
                  <TableCell>{dateUTCToFriendly(pay.date)}</TableCell>
                  <TableCell>{resolveCustomer(pay.customer)}</TableCell>
                  <TableCell>{resolveUser(pay.paymentRequest)}</TableCell>
                  <TableCell>${numberToCurrency(pay.value)}</TableCell>
                  <TableCell>
                    {!(pay.image == null || !pay.image) && <IconButton  onClick={() => dispatch(setPaymentImagePreviewAct(pay.image))}>
                    <Image color={pay.trusted ? "success" : "error"} /> 
                    </IconButton> }
                    <Switch checked={pay.trusted} disabled={pay.trusted} onClick={() =>dispatch(setFeePaymentConfirmImageDialogAct({feePaymentId: pay._id, value: pay.value, index: i}))} />
                  </TableCell>
                  <TableCell>
                    <Button 
                      onClick={() => dispatch(setFeePaymentConfirmPayedDialogAct({
                        index: i, feePaymentId: pay._id, value: pay.value, percentage: 0, remaining: pay.value
                      }))}
                      disabled={pay.trusted !== true || pay.confirmed === false} variant="outlined"> <Paid /> </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3}>Pending: { numberToCurrency(feePayments.reduce((a, el) => a + (!el.trusted ? el.value : 0)   , 0))}</TableCell>
                <TableCell colSpan={3}>Trusted: { numberToCurrency(feePayments.reduce((a, el) => a + (el.trusted ? el.value : 0)   , 0))}</TableCell>

              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

      </>}
      </>
    )
}
import { Button, Grid, Paper } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { inactiveCustomerThunk, showDialogSureDisableCustomerAct } from "../customer-view.slice"
import DialogSureDisableCustomer from "./dialog-sure-disable-customer"
import LoadingIndicator from "../../../../app/components/loading-indicator"
import SetCustomerUser from "./customer-set-user"
import { LocalFireDepartment } from "@mui/icons-material"

export default function CustomerControlsCP(){
  const {customerData, loading} = useAppSelector((state) => state.customer)
  const {currentUser} = useAppSelector((state) => state.login)
  const dispatch = useAppDispatch()

  const confirmInactivateCustomer = () => {
    if(window.confirm(`¿Está seguro de dar de baja al cliente ${customerData!.name}?`)){
      dispatch(inactiveCustomerThunk(customerData!._id))
    }
    
  }
  return(
    <>
      <DialogSureDisableCustomer/>
      <LoadingIndicator open={loading}/>
      {currentUser !== undefined && currentUser.level! === 0 && <Paper sx={{padding: 2}}>
        <Grid container spacing={2}>
          <Grid item>
            <Button size="small" variant="contained" disabled={customerData!.status === 1} color="error" onClick={() => dispatch(showDialogSureDisableCustomerAct(true))}> DAR DE BAJA</Button>
          </Grid>
          {currentUser.level === 0 && <>
            <Grid item>
              <Button endIcon={<LocalFireDepartment/>} onClick={confirmInactivateCustomer} size="small" variant="contained" disabled={customerData!.status === 2} color="warning"> Quemar</Button>
            </Grid>
            <Grid item>
                <SetCustomerUser/>
            </Grid>
          </>
          }
        </Grid>
      </Paper>}
    </>
  )
}
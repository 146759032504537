import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ExternalSituationsState } from "./external-situations.state"
import { SituationFormI } from "../../customer-situations/client-situations/client-situations.state"
import { addExternalSitatuationReq, getExternalSituationsReq, updateExternalSitatuationReq } from "../../../app/services/externals.service"
import { g, s } from "vitest/dist/types-e3c9754d.js"
import { SituationInterface } from "../../../app/models/situation-interface"

const initForm: SituationFormI = {
  description: "",
  descriptionEn: "",  
  order: 0,
  title: "",
  titleEn: ""
}

const initialState: ExternalSituationsState = {
  loading: false,
  showForm: false,
  situations: [], 
  situationForEditId: "",
  situationForm: initForm
}

export const addExternalSitatuationThunk = createAsyncThunk("externalSituations/addExternalSitatuationThunk", async ({ data } : { data : SituationFormI}) => {
  const variable = await addExternalSitatuationReq({situationForm: data})
  return variable
})

export const getxternalSitatuationThunk = createAsyncThunk("externalSituations/getExternalSitatuationThunk", async () => {
  return await getExternalSituationsReq()
})

export const updateExternalSituationThunk = createAsyncThunk("extenalSituations/updateExternalSituationThunk", async ({ data, situationId } : { data : SituationFormI, situationId: string}) => {
  const variable = await updateExternalSitatuationReq({situationForm: data, situationId})
  return variable
})

export const ExternalSituationsSlice = createSlice({
  name: "ExternalSituations",
  initialState,
  reducers: { 
    showAddFormExternalSituationAct: (state, action: PayloadAction<boolean>) => {
      state.showForm = action.payload
    },
    updateInputExternalSituationAct: (state, action: PayloadAction<{key: string, value: any}>) => {
      state.situationForm[action.payload.key] = action.payload.value
    },
    setExternalSituationForEditAct: (state, action: PayloadAction<SituationInterface>) => {
      const { description, descriptionEn, order, title, titleEn, _id } = action.payload
      state.situationForm = {
        description, descriptionEn, order, title, titleEn
      }
      state.showForm = true
      state.situationForEditId = _id
    }
  },
  extraReducers(builder) {
    builder.addCase(getxternalSitatuationThunk.fulfilled, (state, action) => {
      state.situations = action.payload
    }).addCase(addExternalSitatuationThunk.fulfilled, (state, action) => {
      state.showForm = false
      state.situationForm = initForm
      state.situations.push(action.payload)
    }).addCase(updateExternalSituationThunk.fulfilled, (state, action) => {
      const situationIndex = state.situations.findIndex(s => s._id === action.payload._id)
      state.showForm = false
      state.situationForm = initForm
      state.situations[situationIndex] = action.payload
    })


    builder.addMatcher((action) => {
      return action.type.endsWith("/pending") && action.type.includes("externalSituations")
    }, (state, action) => {
      state.loading = true
    }).addMatcher((action) => {
      return action.type.endsWith("/fullfiled") && action.type.includes("externalSituations")
    }, (state, action) => {
      state.loading = false
    })
  },
})

export const { showAddFormExternalSituationAct, updateInputExternalSituationAct, setExternalSituationForEditAct } = ExternalSituationsSlice.actions

export default ExternalSituationsSlice.reducer